import React, { useState, useEffect, useRef, useCallback, ReactNode } from "react";
import { Button, CircularProgress,Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import cssStyles from "./roomplanonboarding.module.scss";
import Box from '@mui/material/Box';
import { gtmPageOnloadTracking, gtmClickTracking } from "@/utils";
import { Texture } from '@/store/builderSlice';
import WallTextureTray from '@/components/builder/WallTextureTray';
import FloorTextureTray from '@/components/builder/FloorTextureTray';
import { useDispatch } from 'react-redux';
import { actions as builderActions } from "@/store/builderSlice";
import { enablePatches } from "immer";
import { useRouter } from "next/router";
import { getUserInfo } from "@/utils/useUserInfo";
import { Placeholder } from "@aws-amplify/ui-react";
import ScanRoomPlan from "./ScanRoomPlan";
import Modal from '@mui/material/Modal';


// Define the interface for room label items
interface RoomLabelItem {
    customerId: string;
    roomType: string;
    roomLabel: string;
    roomId: string;
  }

interface RoomPlanSelectorProps {
  setActiveComponent: (component: string) => void;
  designImage?: any;
  onCloseOverlay?: Function;
  handleOverlayBack?: Function;
}

const RoomPlanSelector: React.FC<RoomPlanSelectorProps> = ({
  setActiveComponent, 
  designImage = {}, 
  onCloseOverlay = () => {}, 
  handleOverlayBack = () => {} 
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);


  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


 
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767); // Detect mobile screen
    setIsTablet(window.innerWidth > 767 && window.innerWidth <= 1024); // Tablet from 768px to 1024px
  };

const roomImages = {
    bedroom: "/assets/img/Choose_Bedroom.png",
    diningroom: "/assets/img/Choose_DinningRoom.png",
    livingroom: "/assets/img/Choose_LivingRoom.png",
    office: "/assets/img/Choose_Office.png",
};

const loginHandler = () => {
  router.push({
    pathname: `/myrooms`,
    query: {hideProjectDetails: true}
  });

  // router.push(`/myrooms?hideProjectDetails=true`);
}

const handleIphoneProApp = () => {
    return <ScanRoomPlan setActiveComponent={setActiveComponent} />;
}

return (
    <>
        <main className="home-container">

            {/* Mweb Form */}
            {isModalOpen && (
            <Modal 
              open={isModalOpen} 
              onClose={() => setIsModalOpen(false)} 
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
            <>
            {(isMobile || isTablet) &&
                <div className={cssStyles.container}>

                <Box component="div" className={cssStyles.mainContainer}>
                <div className={cssStyles.backAndCloseIconMWeb} >
                  <ArrowBackIosIcon sx={{ fontSize: 30, color:'#a5a4a9' }} onClick = {() => handleOverlayBack?.()} />
                  <CloseIcon sx={{ fontSize: 20, color:'#595959'}} onClick={() => { setIsModalOpen(false); onCloseOverlay?.();} }/>
                </div>
                {/* Circle */}
                <Box component="div" className={cssStyles.CircleContainer}>
                    <Box sx={{ 
                         width: { xs: '100px', sm: '80px', md: '100px', lg: '100px' }, 
                         height: { xs: '100px', sm: '80px', md: '100px', lg: '100px' } 
                         }} 
                        component="img"
                        src={
                          designImage?.designImages 
                            ? designImage.designImages 
                            : imageSrc 
                              ? imageSrc 
                              : "/assets/img/1.0.jpg"
                        }
                        alt='img'
                        className={cssStyles.CircularImg} />
                    </Box>
                    
                    {/* Header Section */}
                  <Box component='div' className={cssStyles.formContainer}>
                    <Box component="div"  className={cssStyles.header1} >
                        <Typography component="h2" variant="h2" className={cssStyles.headerText1}>You’ve got great taste!</Typography>
                        <Typography component="h2" variant="h2" className={cssStyles.headerText3}>Now let&apos;s create your room plan. </Typography>
                        <Typography component="h2" variant="h2" className={cssStyles.headerText4}>Choose one of the options below to continue </Typography>
                    </Box>

                    {/* Buttons */}
                    <Box  component='div' className={cssStyles.optionsContainer}>
                        <Box component = 'div' sx= {{height: '137px', cursor: 'pointer', width:'90%', display:'flex', justifyContent:'center'}} onClick={handleIphoneProApp}>
                            <img 
                            src = "/assets/img/iphone_pro_img.png" 
                            alt = "img" 
                            style={{width: '100%', height: 138}}
                            onClick={() => setActiveComponent('scanRoomPlan')}
                            ></img>
                        </Box>
                        <Box component='div' className={cssStyles.optionsContainerOption}  sx= {{cursor: 'pointer'}}>
                            <Typography component='p' className={cssStyles.optionsContainerOptionText} sx = {{color:'#095F6A'}}  onClick={() => setActiveComponent('createRoomPlan')}>Create your room plan in just a few clicks.</Typography>
                        </Box>
                        <Box component='div' className={cssStyles.optionsContainerOption} sx = {{color: 'black', cursor: 'pointer'}} onClick={loginHandler}>
                            <Typography component='p' className={cssStyles.optionsContainerOptionText}>Already have a room plan?</Typography>
                            <Typography component='p' className={cssStyles.optionsContainerOptionText}>Log in and choose a saved room.</Typography>
                        </Box>
                    </Box>
                  </Box>
                </Box>
                </div>

            }

            {/* Desktop Form */}
            {!isMobile && !isTablet &&
                <div className={cssStyles.container}>
                  
                <Box component="div" className={cssStyles.mainContainer}>
                <div className={cssStyles.BackIcon}>
                <ArrowBackIosIcon sx={{ fontSize: 30, color:'#a5a4a9' }} onClick = {() => handleOverlayBack?.()} />
                </div>

                     {/* Circle */}
                     <Box component="div"  className={cssStyles.CircleContainer}>
                    <Box sx={{
                         width: { xs: '100px', sm: '80px', md: '100px', lg: '100px' }, 
                         height: { xs: '100px', sm: '80px', md: '100px', lg: '100px' } 
                         }} 
                        component="img" 
                        src={
                          designImage?.designImages 
                            ? designImage.designImages 
                            : imageSrc 
                              ? imageSrc 
                              : "/assets/img/1.0.jpg"
                        }
                        alt='img' 
                        className={cssStyles.CircularImg} />
                    </Box>
                {/* Header Section */}
                  <Box component='div' className={cssStyles.formContainer}>
                <div className={cssStyles.CloseIcon}>
                  <CloseIcon sx={{ fontSize: 20, color:'#595959'}} onClick={() => { setIsModalOpen(false); onCloseOverlay?.();} }/>
                  </div>

                    <Box component="div"  className={cssStyles.header1} >
                        <Typography component="h2" variant="h2" className={cssStyles.headerText1}>You’ve got great taste!</Typography>
                        <Typography component="h2" variant="h2" className={cssStyles.headerText3}>Now let&apos;s create your room plan. Choose one of the options below to continue </Typography>
                    </Box>

                    {/* Buttons */}
                    <Box  component='div' className={cssStyles.optionsContainer}>
                        <Box component = 'div' sx= {{height: '137px', cursor: 'pointer'}} onClick={handleIphoneProApp}>
                            <img src = "/assets/img/iphone_pro_img.png" alt = "img" style={{width: 368, height: 138}} onClick={() => setActiveComponent('scanRoomPlan')} ></img>
                        </Box>
                        <Box component='div' className={cssStyles.optionsContainerOption}  sx= {{cursor: 'pointer'}} onClick={() => setActiveComponent('createRoomPlan')}>
                            <Typography component='p' className={cssStyles.optionsContainerOptionText} sx = {{color:'#095F6A'}}>Create your room plan in just a few clicks.</Typography>
                        </Box>
                        <Box component='div' className={cssStyles.optionsContainerOption} sx = {{color: 'black', cursor: 'pointer'}} onClick={loginHandler}>
                            <Typography component='p' className={cssStyles.optionsContainerOptionText}>Already have a room plan?</Typography>
                            <Typography component='p' className={cssStyles.optionsContainerOptionText}>Log in and choose a saved room.</Typography>
                        </Box>
                    </Box>
                  </Box>

                </Box>
                </div>
            }
            </>
             </Modal>)}            
        </main>
     
      
    </>
  );
};

export default RoomPlanSelector;