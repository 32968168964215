import React, { useState, useMemo } from 'react';
import styles from './header_menu.module.scss';
import { Link, ListItem, ListItemButton } from "@mui/material";
import TabbedMenuPanel, { Menu } from './tabbed_menu_panel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { trackItem } from '@/utils';

interface HeaderMenuItemProps {
    deviceType?:'mobile' | 'desktop';
    menuJson?: Menu[];
    menuTitle: string;
    menuUrl?: string;
    trackInfo?: any;
}

interface HeaderMenuProps {
    deviceType?: 'mobile' | 'desktop';
    menuJson: { [key: string]: any };
}

const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({ deviceType, menuJson, menuTitle, menuUrl, trackInfo }) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => setDropdownVisible(true);
    const handleMouseLeave = () => setDropdownVisible(false);
    const headerMenuItemClick = (menuTitle: string) => {
        setDropdownVisible(true);
        trackItem(trackInfo || {});
    };

    const memoizedTabbedMenuPanel = useMemo(() => {
        const subMenuClass = deviceType === 'mobile' ? styles.header_menu_wrapper_mobile : styles.header_menu_wrapper;
        const menuClass = isDropdownVisible ? `${subMenuClass} ${styles.visible}` : subMenuClass;
        return (
            <div className={menuClass} id={menuTitle+" memorized tabbed menu pannel"}>
                {deviceType === 'mobile' && (
                    <CloseIcon
                        fontSize="large"
                        style={{
                            position: 'absolute',
                            top: 40,
                            right: 30,
                            cursor: 'pointer',
                            color: 'inherit',
                        }}
                        onClick={() => setDropdownVisible(false)}
                    />
                )}
               {menuJson && <TabbedMenuPanel menuTitle={menuTitle} menuJson={menuJson} deviceType={deviceType} />}
            </div>
        );
    }, [menuJson, isDropdownVisible, deviceType, menuTitle]);

    return (
        <div className={styles.header_menu_item_wrapper}
        onMouseEnter={menuJson && handleMouseEnter}
        onMouseLeave={menuJson && handleMouseLeave} 
    >
            {deviceType === 'mobile'
                ?
                <div id={"MenuTitle " + menuTitle} onClick={() => headerMenuItemClick(menuTitle)} style={{ display: "flex", paddingLeft: "8px" }}>
                    <div style={{textTransform: "uppercase", color: 'black !important'}}>{menuTitle}</div>
                    <ChevronRightIcon fontSize="large" sx={{ color: "teal" }} />
                </div>
                : 

                    <div className={styles.header_menu_item}>
                        <a href={menuUrl} id={"MenuTitle " + menuTitle}
                            className={styles.header_menu_link}
                            onClick={(e) => {
                                trackItem(trackInfo || {});
                            }}
                        >{menuTitle}</a>
                    </div>
                    
            }
            {menuJson && memoizedTabbedMenuPanel}
                </div>
    );
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({ deviceType, menuJson }) => {
    return (
        <>
            {deviceType === 'mobile' ? (
                <div className={styles.drawer_header}>
                    {
                        Object.keys(menuJson).map((key: string, index) => (
                            <React.Fragment key={index}>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        {menuJson[key].subMenu ? (
                                            <HeaderMenuItem
                                                deviceType="mobile"
                                                menuJson={menuJson[key].subMenu}
                                                menuTitle={menuJson[key].name}
                                                menuUrl={menuJson[key].link}
                                                trackInfo={menuJson[key].trackInfo}
                                            />
                                        ) : (
                                            <Link href={menuJson[key].url}>{menuJson[key].name}</Link>
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            </React.Fragment>
                        ))}
                </div>
            ) : (
                <div className={styles.header_menu}>
                    {Object.keys(menuJson).map((key, index, array) => (  
                        <>                      
                        <HeaderMenuItem
                            key={index+"_header_menu"}
                            deviceType="desktop"
                            menuJson={menuJson[key].subMenu}
                            menuTitle={menuJson[key].name}
                            menuUrl={menuJson[key].link}
                            trackInfo={menuJson[key].trackInfo}
                        />
                        {array.length > index+1 && <div className={styles.header_menu_separator}>|</div>}
                        </>
                    ))}
                </div>
            )}
        </>
    );
};

export default HeaderMenu;