import React, { useState } from 'react';
import { Box, Grid, Button, TextField } from '@mui/material';
import { RgbaColorPicker } from "react-colorful";
import { colord } from "colord";

const colors = [
    '#DFCDB1', '#86775F', '#DFD8CD', '#908779', '#CDDBDF', '#749AA5', '#A6AADF', '#6067C1', //'#DFA6DF', //'#CA79CA',
    '#B8A27E', '#635237', '#C5BEB3', '#605749', '#98B0B8', '#5A7078', '#3F459E', '#1C2165', //'#9D6D9D', //'#7C527C',
];

export default function WallColorPicker2(props: { updateColor: Function, applyColor: Function }) {
  const [color, setColor] = useState('#CDDBDF');

  const handleChangeComplete = (color: any) => {
    setColor(color);
    props.updateColor(color);
  };

  return (
    <Box component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 10px' }}>
      <Grid container spacing={1} sx={{ width: '300px' }}>
        {colors.map((selectedColor, index) => (
          <Grid item xs={1.5} key={index}>
            <Box component="div"
              sx={{
                width: '27px',
                height: '27px',
                backgroundColor: selectedColor,
                borderRadius: '50%',
                padding: '5px',
                cursor: 'pointer',
                border: selectedColor === color ? '3px solid black' : '3px solid transparrent',
                transition: 'border 0.1s ease-in-out',
              }}
              onClick={() => handleChangeComplete(selectedColor)}
            >
                <Box component="div"
                    sx={{
                        width: '100%', // Adjust the inner box to take full space
                        height: '100%',
                        backgroundColor: selectedColor,
                        borderRadius: '50%',
                    }}
                />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}