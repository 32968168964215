import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Grid, Menu } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from './tabbed_menu_pannel.module.scss';
import { makeStyles } from '@mui/styles';
import { trackItem, TrackInfo } from "@/utils";


export interface MenuItem {
  meta: any;
  newTab: boolean;
  id?: string;
  name?: string;
  displayName?: string;
  url?: string;
  link?: string;
  imageUrl?: string;
  availability?: string;
  header?: boolean;
  lable?: string;
  trackInfo?: TrackInfo;
}

export interface MenuStyle {
  itemHeight?: string | number;
  itemWidth?: string | number;
  itemBorderRadius?: string | number;
  itemImageFit?: string;
  panelHeight?: string | number;
  panelWidth?: string | number;
  useImages?: boolean;
  panelBorderRadius?: string | number;
  panelBackgroundColor?: string;
  gridColumnWidths?: {xs?: number, sm?: number, md?: number, lg?: number};
  gridSpacing?: {xs?: number, sm?: number, md?: number, lg?: number};
}
export interface Menu {
  name: string;
  description?: string;
  style?: MenuStyle;
  mobileStyle?: MenuStyle;
  items?: MenuItem[];
  columns?: [MenuItem[]];
  trackInfo?: TrackInfo;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: any;
}

interface TabbedMenuPanelProps {
  menuJson: Menu[];
  deviceType?: string;
  menuTitle?: string;  
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component={'div'} sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function TabbedMenuPanel({ deviceType, menuJson, menuTitle }: TabbedMenuPanelProps) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const useStyles = makeStyles({
    customTab: {
      minHeight: '50px !important',
      width: '100%',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      margin: '0',
      overflowWrap: "anywhere",
      whiteSpace: "preserve-spaces"
    },
  });
  //classes prop provided by MUI components to override styles more directly
  const classes = useStyles();

  return (
    <Box id={menuTitle+" tabbed menu pannel"} component={'div'} className={styles.tabbedMenuPanelContainer}>
      <Tabs
        orientation="vertical"
        variant="standard"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        aria-label="Sub Header Menu Tabs"
        className={styles.tabsContainer}
      >
        {menuJson.map((menuTabJson: Menu, index: number) => (
          <Tab
            key={index}
            className={styles.tab}
            classes={{ root: classes.customTab }} // to adjust the inside button height
            icon={<ChevronRightIcon fontSize="large" className={`${styles.tabIcon} ${value === index ? styles.showIcon : styles.hideIcon}`} />}
            iconPosition="end"
            label={menuTabJson.name}
            onClick={() => {
              trackItem(menuTabJson.trackInfo || {})
            }}
            onMouseOver={() => {
              trackItem(menuTabJson.trackInfo || {})
              if (deviceType !== 'mobile') {
                setValue(index);
              }
            }}
          />
        ))}
      </Tabs>
      {menuJson.map((menuTabPanelJson: Menu, index: number) => { 
        let panelStyle = deviceType == 'mobile' ? {...menuTabPanelJson.style, ...menuTabPanelJson.mobileStyle} : menuTabPanelJson.style;
        return (
        <TabPanel key={index} value={value} index={index} className={styles.tabPanel}>
          <Grid container className={styles.gridContainer}
            sx={{ height: (panelStyle?.panelHeight) || "300px" }}
            spacing={panelStyle?.gridSpacing || { xs: 1, md: 2 }}
          >

            {menuTabPanelJson.items?.map((item, itemIndex) => (
              <Grid item
                xs={12/(panelStyle?.gridColumnWidths?.xs || 1)}
                sm={12/(panelStyle?.gridColumnWidths?.sm || 2)}
                md={12/(panelStyle?.gridColumnWidths?.md || 3)}
                lg={12/(panelStyle?.gridColumnWidths?.lg || 4)}
                key={itemIndex}>
                <a href={item.url || item.link} style={{margin: '0px', padding: '0px'}}
                  target={item.newTab === false ? "_self" : "_blank"}
                  onClick={() => {
                    trackItem(item.trackInfo || {})
                  }} >
                  <Box component={'div'} className={styles.itemBox}>
                    <div className={styles.itemIcon} style={{ 

                      height: panelStyle?.itemHeight || "200px",
                      width:  panelStyle?.itemWidth|| "200px" }}>
                      <div className={styles.itemImage} style={{
                         backgroundImage: `url(${item.imageUrl})`,
                          backgroundSize: panelStyle?.itemImageFit || "contain",
                          borderRadius: panelStyle?.itemBorderRadius || "0px"
                         }}> 
                         {item.meta?.overlayText && <div className={styles.overlayText}>{item.meta.overlayText}</div>}
                         </div>
                    </div>
                    <div className={styles.itemLabel}>{item.displayName || item.name}</div>
                  </Box>
                </a>
              </Grid>
            ))}

            {menuTabPanelJson.columns?.map((column, columnIndex) => (
              <Grid item
                xs={12 / (panelStyle?.gridColumnWidths?.xs || 1)}
                sm={12 / (panelStyle?.gridColumnWidths?.sm || 2)}
                md={12 / (panelStyle?.gridColumnWidths?.md || 3)}
                lg={12 / (panelStyle?.gridColumnWidths?.lg || 4)}
                key={columnIndex}>
                  <Box component={'div'} className={styles.columnItem}>
                    {column.map((item, itemIndex) => (
                      <a href={item.url || item.link || "#"} target={item.newTab === false ? "_self" : "_blank"} key={itemIndex}
                        onClick={() => {
                          trackItem(item.trackInfo || {})
                        }}
                      >
                        <div key={itemIndex} className={`${styles.itemLabel}`}>
                          {panelStyle?.useImages && item.imageUrl && <img src={item.imageUrl} alt={item.name} className={styles.itemImage} />}
                          <div className={`${styles.itemText} ${item.header ? styles.headerItem : ''}`}>
                            {item.displayName || item.name} {/*item.header && "  > "*/} 
                            {item.lable && <sup className={styles.newSuperscript}>{item.lable}</sup>}
                          </div>
                        </div>
                      </a>
                    ))}
                  </Box>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      ) } )  }
    </Box>
  );
}

export default TabbedMenuPanel;