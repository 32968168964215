import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Image from 'next/image';
import { trackItem } from "@/utils";
import cssStyles from "./cms_renderer.module.scss";
import ReactMarkdown from 'react-markdown'
import { prepareCardsForCategoriesGrid } from '@/translator';
//TODO : need to refactor the existing product_cards.tsx to replace below shop_product_cards.tsx
import generateProductCards from "@/components/products/shop_product_cards";
import CommonCarouselComponent from "@/components/carousal/common_carousel";
import GenerateCategoryCard from "@/components/products/category_cards";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


const CMS_HOST = process.env.CMS_HOST;

export const transformTextToHtml = (text: string, panelClassName?: string, id?: string) => {

    if (!panelClassName) {
        panelClassName = "sectionDescription";
    }
    function transformMarkdown(text: string, classNameStr: string, id?: string) {
        return <ReactMarkdown
            components={{
                p: ({ node, ...props }) => <p key={id || 'description'} className={cssStyles[classNameStr]} style={{ margin: 0 }} {...props} />
            }}
        >
            {text}
        </ReactMarkdown>;
    }

    if (text.startsWith('<LARGE>')) {
        return transformMarkdown(text.replace('<LARGE>', '').replace('</LARGE>', ''), panelClassName + "LargeText", id) 
    } else if (text.startsWith('<CENTER>')) {
        return transformMarkdown(text.replace('<CENTER>', '').replace('</CENTER>', ''), panelClassName + "CenterText", id);
    } else if (text.startsWith('<BOLD>')) {
        return transformMarkdown(text.replace('<BOLD>', '').replace('</BOLD>', ''), panelClassName + "BoldText", id);
    } else if (text === 'ELINE') {
        return <div key={id}>&nbsp;</div>;
    } else {
        return transformMarkdown(text, '', id);
    }
}

export function transformDescription(description: any, panelClassName?: string, trackId?: any): React.ReactNode {
    if (typeof description === "object") {
        return description.map((descItem: string, index: number) => (
            transformTextToHtml(descItem, panelClassName, trackId + index)
        ))
    } else return transformTextToHtml(description, panelClassName, trackId)
}

const CmsRenderer = (props: any) => {
    //console.log("CmsRenderer page : props", props);
    const { vendorName, vendorHomePage, env, vendorsApiData, pageCmsData, sectionCmsData, sectionCmsKey } = props;
    const brandPathEntry = vendorName && vendorName !== "nestingale" ? `/${vendorName}` : "";

    type RenderFunctionType = {
        [key: string]: (sectionData: any) => JSX.Element | null;
      };

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    function renderHeroImage(sectionData: any) : JSX.Element | null {
        return (
            <section className={cssStyles.heroSectionContainer}>
                <div className={cssStyles.heroSectionTextContainer}>
                    <Typography variant="h1" component="h1" className={cssStyles.heroSectionText1}>
                        {sectionData.title}
                    </Typography>
                    <Typography variant="h2" component="h2" className={cssStyles.heroSectionText2}>
                        {transformDescription(sectionData.description, undefined, sectionData.trackId)}
                    </Typography>
                    <div className={cssStyles.heroSectionBottom}>
                        <Button id={sectionData.buttons[0].id} className={cssStyles.heroSectionButton} color="primary" href={sectionData.buttons[0].link}
                            target={sectionData.buttons[0].newTab ? "_blank" : "_self"}
                            onClick={() => { trackItem(sectionData.buttons[0].trackInfo) }}
                        >
                            {sectionData.buttons[0].title}
                        </Button>
                    </div>
                </div>
                <div className={cssStyles.heroSectionMedia}>
                    <Image alt={sectionData.title} src={sectionData.backgroundImageUrl} layout="fill" objectFit="cover" />
                </div>
            </section>
        );
    }

    function renderImageButton(item: any, index: number = 1): JSX.Element | null {
        const baseClassName = "imgButtonCircular";
        return (
            <div key={item.trackId + index} className={cssStyles[baseClassName]}>
                <div
                    className={cssStyles[`${baseClassName}__outerCircle`]}
                    onClick={(e) => { trackItem(item.trackInfo); }}
                >
                    <div
                        className={cssStyles[`${baseClassName}__innerCircle`]}
                        style={{
                            height: item.uiCompType?.options?.height || 200,
                            width: item.uiCompType?.options?.width || 200,
                        }}
                    >
                        <div
                            className={cssStyles[`${baseClassName}__circle`]}
                            style={{ backgroundImage: `url(${item.imageUrl})` }}
                        >
                        </div>
                    </div>
                </div>

                <Typography variant="h2" className={cssStyles[`${baseClassName}Text`]}>
                    {item.title}
                </Typography>
            </div>
        );
    }

    function renderImageButtons(sectionData: any): JSX.Element | null {
        const panelClassName = "imgButtons";
        const items = sectionData.items || sectionData.buttons;
        return (
            <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
                <div className={cssStyles[panelClassName + "Container"]}>
                    {items?.map((item: any, index: number) => (
                        renderImageButton(item, index)
                    ))}
                </div>
            </section>
        );
    }

    function renderPanel(sectionData: any): JSX.Element | null {
        const orderClass = sectionData.uiCompType?.options?.imgPosition == "right" ? cssStyles.swapOrder : '';
        const panelClassName = "panel";
        const hasTextToShow = sectionData.title || sectionData.description || sectionData.buttons;

        return (
            <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
                <Grid container spacing={2} className={`${cssStyles[panelClassName + "Container"]} ${orderClass}`}>
                    {sectionData.imageUrl &&
                        <Grid item xs={12} md={hasTextToShow ? 6 : 12} className={cssStyles[panelClassName + "ImageGrid"]}>
                            <div className={cssStyles[panelClassName + "Image"]}>
                                <Image alt={sectionData.title} src={sectionData.imageUrl} layout="fill" objectFit="cover" />
                            </div>
                        </Grid>
                    }
                    {hasTextToShow &&
                        <Grid item xs={12} md={sectionData.imageUrl ? 6 : 12} className={cssStyles[panelClassName + "TextGrid"]}>
                            <Box component="div" className={cssStyles[panelClassName + "Text"]}>
                                
                                {sectionData.title && <Box component="h3" className={cssStyles[panelClassName + "Title"]}>
                                    {sectionData.title}
                                </Box>}
                                
                                {sectionData.description && <Box component="div" className={cssStyles[panelClassName + "Description"]}>
                                    {transformDescription(sectionData.description, undefined, sectionData.trackId)}
                                </Box>}

                                {sectionData.buttons && sectionData.buttons.length > 0 &&
                                    <span className={cssStyles[panelClassName + "Button"]}>
                                        <Button id={sectionData.trackId} color="secondary" href={sectionData.buttons[0].link}
                                            target={sectionData.buttons[0].newTab ? "_blank" : "_self"}
                                            onClick={() => { trackItem(sectionData.buttons[0].trackInfo) }}
                                        >{sectionData.buttons[0].title}</Button>
                                    </span>
                                }
                            </Box>
                        </Grid>
                    }
                </Grid>
            </section>
        );
    }

    function renderGridPanel(sectionData: any): JSX.Element | null {
        const panelClassName = "gridPanel";
        return (
            <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>

                {sectionData.title && <Typography variant="h2" component="h2" className={cssStyles.sectionTitle}>
                    {sectionData.title}
                </Typography>}

                <Grid container spacing={2} className={`${cssStyles[panelClassName + "GridContainer"]}`}>
                    {sectionData.items?.map((item: any, index: number) => (
                        <Grid item xs={12} md={12/sectionData.items.length} key={sectionData.trackId + index}>
                            <div className={cssStyles[panelClassName + "GridItem"]}>
                                {item.imageUrl &&
                                    <div className={cssStyles[panelClassName + "Image"]}>
                                        <Image alt={item.title} src={item.imageUrl} layout="fill" objectFit="cover" />
                                    </div>
                                }
                                <Box component="div" className={cssStyles[panelClassName + "Text"]}>
                                    {item.title && (
                                        <Box component="h3" className={cssStyles[panelClassName + "Title"]}>
                                            {item.title}
                                        </Box>
                                    )}
                                    {item.description && transformDescription(item.description, undefined, item.trackId)
                                    }
                                </Box>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </section>
        );
    }

    function renderImageButtonsWithPanel(sectionData: any): JSX.Element | null {
        const panelClassName = "imageButtonsWithPanel";
        return (
            <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>   
                {sectionData.title && <Typography variant="h2" component="h2" className={cssStyles.sectionTitle}>
                    {sectionData.title}
                </Typography>}
                {sectionData.description && transformDescription(sectionData.description, undefined, sectionData.trackId)}
                {sectionData.buttons && sectionData.buttons.length > 0 &&
                    renderImageButtons(sectionData)         
                }
                {sectionData.pannels && sectionData.pannels.length > 0 &&
                    <div className={cssStyles[panelClassName + "Panels"]}>
                        {sectionData.pannels?.map((item: any, index: number) => (
                            renderPanel(item)
                        ))}
                    </div>
                }
            </section>
        );
    }

    function renderCarousel(sectionData: any): JSX.Element | null {
        const panelClassName = "carousel";
            return (
                <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
                    {sectionData.title && <Typography variant="h2" component="h2" className={cssStyles.sectionTitle}>
                        {sectionData.title}
                    </Typography>}
                    <Grid container className={cssStyles[panelClassName + "Container"]}>
                        <CommonCarouselComponent fullWidth={true} type="scrollable" 
                            cards={GenerateCategoryCard( 
                                prepareCardsForCategoriesGrid(sectionData.items, brandPathEntry, vendorName),
                                handleProductSelection,
                                (sectionData.uiCompType?.options?.height || "320"),
                                (sectionData.uiCompType?.options?.width || "320"),
                                sectionData.trackId
                            )
                            .map((productCard: any) => {
                                return (<Grid key={productCard.name} item>{productCard}</Grid>);
                            })} />
                    </Grid>
                </section>
            );
    }

    function renderAccordion(sectionData: any): JSX.Element | null {
        const panelClassName = "accordion";

            return (
                <section id={sectionData.trackId} className={cssStyles[panelClassName + "Section"]}>
                    {sectionData.title && <Typography variant="h2" component="h2" className={cssStyles.sectionTitle}>
                        {sectionData.title}
                    </Typography>}
                    {sectionData.description && <Typography variant="body1" className={cssStyles[panelClassName + "Description"]}>
                        {transformDescription(sectionData.description, "", sectionData.trackId)}                
                    </Typography>}
                    <div className={cssStyles[panelClassName + "Container"]}>
                    {sectionData.items && sectionData.items.map((item: any, index: number) => (
                        <Accordion key={index} expanded={expanded === `panel${index}`} 
                            onChange={handleChange(`panel${index}`)} 
                            sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}
                            className={cssStyles[panelClassName + "AccordionItem"]}>
                            <AccordionSummary
                                expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                                className={cssStyles["AccordionSummary"]}
                                sx={{
                                    minHeight: 'initial !important',
                                    '.Mui-expanded': {
                                        margin: '10px 0 !important'
                                    }
                                }}
                            >
                                <strong>{item.title}</strong>
                            </AccordionSummary>
                            <AccordionDetails className={cssStyles["AccordionDetails"]}>
                                {transformDescription(item.description, undefined, item.trackId)}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    </div>
                </section>
            );
    }

    const handleProductSelection = (cardIndex: any, event?: any, card?: any, sectionName?: string) => {
        console.log('Selected cardIndex: ' + cardIndex + "cardId : " + JSON.stringify(card) + "sectionName : " + sectionName);
        trackItem({
            id: card.id,
            event: sectionName + 'Click',
            name: card.name,
            section: sectionName
        });
    }

    const renderFunctions: RenderFunctionType = {
        renderHeroImage,
        renderPanel,
        renderGridPanel,
        renderImageButton,
        renderImageButtons,
        renderImageButtonsWithPanel,
        renderCarousel,
        renderAccordion
    };

    const renderSection = (sectionKey: string, sectionData: any) => {
        sectionData = sectionData || pageCmsData.sections[sectionKey];
        if (!sectionData) {
          console.error(`No section found for key: ${sectionKey}`);
          return null;
        }
        const renderFunction = sectionData.uiCompType?.method;
        if (renderFunction && renderFunction in renderFunctions) {
          return renderFunctions[renderFunction](sectionData);
        } else {
          console.error(`No render function found for method: ${renderFunction}`);
          return null;
        }
      };


    return (
        <>
            {sectionCmsData && sectionCmsKey
                ? <React.Fragment key={sectionCmsKey}>
                    {renderSection(sectionCmsKey, sectionCmsData)}
                </React.Fragment>

                : pageCmsData.order?.map((secKey: string) => (
                    <React.Fragment key={secKey}>
                        {renderSection(secKey, pageCmsData.sections[secKey])}
                    </React.Fragment>
                ))}
        </>
    )
}

export default CmsRenderer;

