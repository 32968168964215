import React, { useState, useEffect, useRef, useCallback, ReactNode } from "react";
import { Button, CircularProgress,Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import cssStyles from "./roomplanonboarding.module.scss";
import Box from '@mui/material/Box';
import { gtmPageOnloadTracking, gtmClickTracking } from "@/utils";
import { Texture } from '@/store/builderSlice';
import WallTextureTray from '@/components/builder/WallTextureTray';
import FloorTextureTray from '@/components/builder/FloorTextureTray';
import { useDispatch } from 'react-redux';
import { actions as builderActions } from "@/store/builderSlice";
import { enablePatches } from "immer";
import { useRouter } from "next/router";
import { getUserInfo } from "@/utils/useUserInfo";
import { Placeholder } from "@aws-amplify/ui-react";
import Modal from '@mui/material/Modal';

// Define the interface for room label items
interface RoomLabelItem {
  customerId: string;
  roomType: string;
  roomLabel: string;
  roomId: string;
}

interface ScanRoomPlanProps {
  setActiveComponent: (component: string) => void;
  designImage?: any;
  onCloseOverlay?: Function
}

const ScanRoomPlan: React.FC<ScanRoomPlanProps> = ({setActiveComponent, designImage, onCloseOverlay}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [name, setName] = useState('');
  const [roomType, setRoomType] = useState<string>('');
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [userInfo, setUserInfo] = useState<any | null>(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false); 
  const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(false);
  const [surfaceType, setSurfaceType] = useState<string | null>(null);
  const tempSelectedWallType = useRef<any | null>(null);
  const tempSelectedFloorType = useRef<any | null>(null);
  const [selectedWallType, setSelectedWallType] = useState<any | null>(tempSelectedWallType.current);
  const [selectedFloorType, setSelectedFloorType] = useState<any | null>(null);
  const [imageSrc, setImageSrc] = useState("");
  const [placeholder, setPlaceholder] = useState('');
  const [counter, setCounter] = useState(1); // Initial counter
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);


  useEffect(() => {
    handleResize();
   
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767); // Detect mobile screen
    setIsTablet(window.innerWidth > 767 && window.innerWidth <= 1024); // Tablet from 768px to 1024px
  };

return (
    <>
        <main className="home-container">
        {isModalOpen && (
        <Modal 
        open={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <>
            {/* Mweb Form */}
            {(isMobile || isTablet) &&
                <div className={cssStyles.container}>

                <Box component="div" className={cssStyles.mainContainer}>
                <div className={cssStyles.backAndCloseIconMWeb} >
                  <ArrowBackIosIcon sx={{ fontSize: 30, color:'#a5a4a9' }} onClick={() => setActiveComponent('')} />
                  <CloseIcon sx={{ fontSize: 20, color:'#595959'}} onClick={() => { setIsModalOpen(false); onCloseOverlay?.();} }/>
                </div>
                {/* Circle */}
                <Box component="div" className={cssStyles.CircleContainer}>
                    <Box sx={{ 
                         width: { xs: '100px', sm: '80px', md: '100px', lg: '100px' }, 
                         height: { xs: '100px', sm: '80px', md: '100px', lg: '100px' } 
                         }} 
                        component="img"
                        src={designImage? designImage.designImages : imageSrc?imageSrc:"assets/img/1.0.jpg"}
                        alt='img'
                        className={cssStyles.CircularImg} />
                    </Box>


                {/* Header Section */}

                    <Box component='div' className={cssStyles.formContainer}>
                            <Box component="div"  className={cssStyles.header1} >
                                <Typography component="h2" variant="h2" className={cssStyles.headerText1}> You’re almost there!</Typography>
                                <Typography component="h2" variant="h2" className={cssStyles.headerText3}>Download our app now and scan your room</Typography>
                                <Typography component="h2" variant="h2" className={cssStyles.headerText4}>to create a room plan.</Typography>
                            </Box>

                            <Box component="div"  sx={{display: 'flex',justifyContent: 'center', height: '11.7%', margin: '13px 0 0'}}>
                                <img src="/assets/img/logo_img.png" alt="apple_download_img" />
                                <img src="/assets/img/App_store_download.png" alt="apple_download_img" />
                            </Box>

                            <Box component="div"  sx={{display: 'flex',justifyContent: 'center'}}>
                                <img className={cssStyles.qr_code_img} src="/assets/img/scan_room_img.png" alt='QR' />
                            </Box>
                            
                            <Box component="div"  className={cssStyles.header1} >
                                <Typography component="p" variant="h2" className={cssStyles.headerText3}>After you’ve scanned your room on the app, visit</Typography>
                                <Typography component="p" variant="h2" className={cssStyles.headerText4}>Nestingale.com/myrooms on your desktop to start </Typography>
                                <Typography component="p" variant="h2" className={cssStyles.headerText4}>designing it.</Typography>
                            </Box>

                            {/* Button */}
                            <Box component="div"  className={cssStyles.buttonContainer}>
                                <Button variant="contained" sx={{width:'305px', margin: '9px 0 0'}}>
                                    OK
                                </Button>
                            </Box>

                        </Box>
                
                </Box>
                </div>

            }

            {/* Desktop Form */}
            {!isMobile && !isTablet &&
                <div className={cssStyles.container}>
                    <Box component="div" className={cssStyles.mainContainer}>
                    <div className={cssStyles.BackIcon}>
                      <ArrowBackIosIcon sx={{ fontSize: 30, color:'#a5a4a9' }} onClick={() => setActiveComponent('')}/>
                    </div>
                        {/* Circle */}
                        <Box component="div"  className={cssStyles.CircleContainer}>
                        <Box sx={{
                            width: { xs: '100px', sm: '80px', md: '100px', lg: '100px' }, 
                            height: { xs: '100px', sm: '80px', md: '100px', lg: '100px' } 
                            }} 
                            component="img" 
                            src={designImage? designImage.designImages : imageSrc?imageSrc:"assets/img/1.0.jpg"}
                            alt='img' 
                            className={cssStyles.CircularImg} />
                        </Box>
                        {/* Header Section */}
                        <Box component='div' className={cssStyles.formContainer}>
                        <div className={cssStyles.CloseIcon}>
                          <CloseIcon sx={{ fontSize: 20, color:'#595959'}} onClick={() => {setIsModalOpen(false); onCloseOverlay?.();}}/>
                        </div>
                            <Box component="div"  className={cssStyles.header1} >
                                <Typography component="h2" variant="h2" className={cssStyles.headerText1}> You’re almost there!</Typography>
                                <Typography component="h2" variant="h2" className={cssStyles.headerText3}>Scan the code to download our app now and </Typography>
                                <Typography component="h2" variant="h2" className={cssStyles.headerText4}>scan your room to create a room plan.</Typography>
                            </Box>

                            <Box component="div"  sx={{display: 'flex',justifyContent: 'center', height: '11.7%', marginTop: '30px', margin: '13px 0 0'}}>
                                <img src="/assets/img/App_store_download.png" alt="apple_download_img" />
                            </Box>

                            <Box component="div"  sx={{display: 'flex',justifyContent: 'center', marginTop: '10px'}}>
                                <img className={cssStyles.qr_code_img} src="/assets/img/QR_code_download.png" alt='QR' />
                            </Box>
                            
                            <Box component="div"  className={cssStyles.header1} >
                                <Typography component="p" variant="h2" className={cssStyles.headerText5}>After you’ve scanned your room on the app, visit</Typography>
                                <Typography component="p" variant="h2" className={cssStyles.headerText4}>your rooms page to start designing it.</Typography>
                            </Box>

                            {/* Button */}
                            <Box  component="div"  className={cssStyles.buttonContainer}>
                                <Button variant="contained" sx={{width:'305px'}}>
                                    LET’S GO
                                </Button>
                            </Box>

                        </Box>

                    </Box>
                </div>
            }
            </>
             </Modal>)}
                {isLoaderVisible && (
                        <CircularProgress/>
                    )
                }
                
        </main>
     
      
    </>
  );
};

export default ScanRoomPlan;