import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from "@mui/material";
import cssStyles from './common_carousel.module.scss';

export interface CommonCarouselData {
    cards : any,
    type?: any
    fullWidth?: boolean;
}

const CommonCarouselComponent: React.FC<CommonCarouselData> = ({type, cards, fullWidth}) => {
    const carouselBoxEle = React.useRef<HTMLDivElement>();
    const [showLeftNav, setShowLeftNav] = useState(true);
    const [showRightNav, setShowRightNav] = useState(true);
    const [toogleShowText,setToogleShowText ] = useState(true);
    const showHideNavArrows = () => {
        const parent =  carouselBoxEle.current as HTMLDivElement;
        if(parent.scrollLeft > 0 ) {
            setShowLeftNav(true);
        }else{
            setShowLeftNav(false);
        }
        if(parent.scrollLeft < (parent.scrollWidth - parent.clientWidth)) {
            setShowRightNav(true);
        }else{
            setShowRightNav(false);
        }
    }

    const scrollCarousel = (direction: string) => {
        
        if ( carouselBoxEle.current ) { 
            const parent =  carouselBoxEle.current as HTMLDivElement;
            //const childImg = parent.querySelectorAll('img');
            //const childWidth = childImg[0].width;
            const scrollWidth = direction == 'left' ? -(parent.clientWidth) : parent.clientWidth;            
            parent.scrollBy({ left: scrollWidth, top: 0, behavior: 'smooth' })
        }
    }

    const toggleCarousel = () => {
        if(carouselBoxEle.current) {
            setToogleShowText(!toogleShowText);
            const parent =  carouselBoxEle.current as HTMLDivElement;
            parent.style.height = toogleShowText ? 'auto': '48px';
        }
    }

    useEffect(() => {
        showHideNavArrows();
    },[cards])
    return (
        <>
        <div className={`${type ? cssStyles[type]: ""} ${cssStyles.commonCarouselWrapper} ${fullWidth ? cssStyles.full_width :''}`}>
            {showLeftNav && 
                <div 
                    className={`${cssStyles.arrowBtns} ${cssStyles.prev}` }
                    onClick={()=> scrollCarousel('left')}
                >
                    <ArrowBackIosIcon  fontSize="large" sx={{ color: (theme) => theme.palette.secondary.main }} />
                </div>
            }
            <Box component="div" className={cssStyles.noscrollContainer} ref={carouselBoxEle} onScroll={() => showHideNavArrows()}>
               {cards}
            </Box>
            
            {showRightNav &&
                <div 
                className={`${cssStyles.arrowBtns} ${cssStyles.next}` }
                onClick={()=> scrollCarousel('right')}
                    >
                        <ArrowForwardIosIcon fontSize="large" sx={{ color: (theme) => theme.palette.secondary.main }} />
                </div>
            }
        </div>
        {type !== 'scrollable' && <Box component="div" className={cssStyles.show_more}>
            <a href="javascript:void(0)" onClick={() => toggleCarousel()}>{toogleShowText ? "More +": "Less -"}</a></Box>}
        </>
    )
}
export default CommonCarouselComponent;