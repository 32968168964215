import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
    useCallback
  } from "react";
import { Button, Dialog, DialogContent, IconButton, Typography,Grid,useMediaQuery,} from "@mui/material";
  import { getUserInfo } from "@/utils/useUserInfo";
  import Box from "@mui/material/Box";
import {Close,FavoriteBorder, GridView,} from "@mui/icons-material";
  import AddIcon from "@mui/icons-material/Add";
  import RemoveIcon from "@mui/icons-material/Remove";
  import ViewInArIcon from "@mui/icons-material/ViewInAr";
  import Image from "next/image";
  import { getUserCartInfo, CartInfo } from "@/utils/useUserInfo"; 
  import { useShopify } from "@/store/shopifyReducer";
  import { updateCartData } from "@/utils/useUserInfo";
  import { gtmClickTracking, navigateWithHistory,gtmPageOnloadTracking } from "@/utils";
  import { useRouter } from "next/router";
  import { getLoggedInEmailAddress } from "@/utils";
  import { CART_TOKEN, GCART_TOKEN } from "@/utils";
import { useTheme } from "@emotion/react";
import Favorite from "@mui/icons-material/Favorite";
import axios from "axios";
import viewModeGridIcon from '../../assets/images/view-icon-grid@2x.png'
import viewModeSingleIcon from '../../assets/images/view-icon-single@2x.png'
import CartIcon from '../../assets/images/addtocart-icon-outline@2x.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
interface DesignOverlayProps {
  open: boolean;
  onClose: () => void;
  design: any;
  onStartDesign: () => void;
  recommendations: any;
  handleRecommendationChange: (design: any) => void;
  userInfo: any;
  ontoggleFav:any
  showFavFromFavList:any
}

  interface EachProductCardHandle {
    handleAddAllToCartRef: () => Promise<void>;
  }
  interface EachProductCardProps {
    eachProduct: any;
    viewMode: string;
    userInfo: any;
    onStateChange?: (newState: any) => void;
    index: number;
  }
//each product component
  const EachProductCard = forwardRef<EachProductCardHandle, EachProductCardProps>(
    ({ eachProduct={}, viewMode='single', userInfo={},onStateChange,index }: any, ref) => {
      const [quantity, setQuantity] = useState(0);
      const [expanded, setExpanded] = useState(false);
      const timeOutId = useRef<any>(null);
      const [hover, setHover] = useState(false);
      const [addToCartClicked, setAddToCartClicked] = useState<boolean>(false);
      const [productAvailability, setProductAvailability] = useState("available");
      const [productVariantId, setProductVariantId] = useState(undefined);
      const [checkoutLoaded, setCheckoutLoaded] = useState(false);
      const [availabilityUpdated, setAvailabilityUpdated] = useState(false);
      const isMobile = useMediaQuery("(max-width: 600px)");
      useEffect(() => {
        onStateChange(index, { checkoutLoaded, availabilityUpdated,productAvailability });
      }, [checkoutLoaded, availabilityUpdated, onStateChange,productAvailability]);
    //   useEffect(() => {
    //     setQuantity(0);
    //   }, [productAvailability]);
      const {
        createCheckout,
        addVariant,
        getClient,
        checkoutID,
        checkoutState,
        updateQuantity,
      } = useShopify();
      const handleAddToCart = async () => {
        if (eachProduct?.availability?.toLowerCase() !== "available"||productAvailability.toLowerCase() !== "available") {
          return;
        }
        if (quantity === 0) {
            gtmClickTracking({
                event:'Add Product',
                pageType: "new design details page pop-up",
                addedProduct:eachProduct
            })

          setAddToCartClicked(true);
          setQuantity(1);
          setExpanded(true);
          resetTimer();
          await reUseAddToCart();
          
        } else {
          setExpanded(true);
          resetTimer();
        }
      };
      async function handleAddAllToCartRef() {
        if (eachProduct?.availability?.toLowerCase() !== "available"||productAvailability.toLowerCase() !== "available") {
          return;
        }
        setAddToCartClicked(true);
        
        await reUseAddToCart();
        if(productVariantId && checkoutID) setQuantity(1);
      }
      useImperativeHandle(ref, () => ({
        handleAddAllToCartRef,
      }));
      async function reUseAddToCart() {
        setAddToCartClicked(true);
  
        if (checkoutID&&productVariantId) {
          if (typeof window !== "undefined") {
            if (getLoggedInEmailAddress()) {
              //@ts-ignore
              window.setCookieWithoutSecure(CART_TOKEN, checkoutState.id, 10);
            } else {
              //@ts-ignore
              window.setCookieWithoutSecure(GCART_TOKEN, checkoutState.id, 10);
            }
          }
          try {
            let lineItemsToAdd = [
              {
                variantId: productVariantId,
                quantity: 1,
              },
            ];
  
            await addVariant(checkoutID, lineItemsToAdd);
          } catch (error) {
            console.error("Error adding product to cart:", error);
            setProductAvailability("unavailable");
            setAddToCartClicked(false);
          }
        } else {
          console.error("No variant ID or checkout ID available");
          setProductAvailability("unavailable");
          setAddToCartClicked(false);
        }
      }
      useEffect(() => {
        if(!addToCartClicked) return
        async function goToCart() {
          try {
        //   if(!userInfo?.crtD){
        //     let userInfo = await getUserInfo('',true);
        //     await getUserCartInfo(userInfo);
        //     const response = await updateCartData(userInfo, checkoutState);
        //     console.log("----[[slug]] after updateCartData--------", response);
        //     setAddToCartClicked(false);
        //     return
        //     }
            const response = await updateCartData(userInfo, checkoutState);
            console.log("----[[slug]] after updateCartData--------", response);
            setAddToCartClicked(false);
          } catch (error) {
            console.log(error);
            console.log("error in updating the cart");
          }
  
          //wait for checkout state to update after clicking add to cart
          //before taking the user to the cart page.
  
          // gtmClickTracking({
          //     event: 'Pdp_add_to_cart_clicked',
          //     ecommerce: {
          //         currency: 'USD',
          //         value: productDetails?.productMetaData?.price * qty,
          //         items: [{
          //             item_id: productId,
          //             item_name: productDetails?.productName,
          //             price: productDetails?.productMetaData?.price,
          //             quantity: qty
          //         }]
          //     }
          // });
          // setTimeout(() => {
          //   navigateWithHistory(router, `/cart`);
          // }, 5000);
        }
        goToCart();
      }, [checkoutState]);
      const increaseQuantity = async () => {
        gtmClickTracking({
            event:'Quantity Increased',
            pageType: "new design details page pop-up",
            increasedProduct:eachProduct
        })
        setAddToCartClicked(true);
        setQuantity((prev) => prev + 1);
        setExpanded(true);
        resetTimer();
        await reUseAddToCart();
      };
  
      const decreaseQuantity = () => {
        gtmClickTracking({
            event:'Quantity Decreased',
            pageType: "new design details page pop-up",
            decreasedProduct:eachProduct
        })
        if (quantity > 0) {
            setQuantity((prev) => prev - 1);

          const cartItemData = checkoutState?.lineItems?.filter(
            (eachItem: any) => eachItem?.variant?.id === productVariantId
          );
          if (cartItemData[0]?.quantity > 0 && cartItemData?.length > 0) {
            setAddToCartClicked(true);
            setExpanded(true);
            resetTimer();
            const checkoutId = checkoutState?.id;
            const updatedQuantity = cartItemData[0]?.quantity - 1;
            updateQuantity(cartItemData[0]?.id, updatedQuantity, checkoutId);

          }
        }
      };
  
      const resetTimer = () => {
        clearTimeout(timeOutId.current);
        timeOutId.current = setTimeout(() => {
          setExpanded(false);
        }, 4000);
      };
  
      useEffect(() => {
        // Clear the timeout when the component unmounts
        return () => {
          clearTimeout(timeOutId.current);
        };
      }, []);
      // Load Shopify checkout and handle product availability
  
      useEffect(() => {
        async function loadShopifyCheckout() {
          // let userInfo = await getUserInfo();
          let checkoutId: any = null;
        //   if (typeof window !== 'undefined' && userInfo?.status == 'DONE') {
          try {
            checkoutId = userInfo?.crtD?.cartId;
  
            if (!checkoutId) {
              const cartData: CartInfo = await getUserCartInfo(userInfo);
              console.log('cartData---->>: ', cartData);

              checkoutId = cartData?.cartId;
            }
            //@ts-ignore
            checkoutId = checkoutId || window.getCookie(CART_TOKEN);
            checkoutId =
              (checkoutId == "null" || checkoutId == "undefined")
                ? null
                : checkoutId;
  
            if (!checkoutId) {
              //@ts-ignore
              checkoutId = window.getCookie(GCART_TOKEN);
              checkoutId =
                (checkoutId == "null" || checkoutId == "undefined")
                  ? null
                  : checkoutId;
            }
             //if checkout is empty create checkout or if checkout Id is present get the data from shopify
             console.log('creating checkout with  checkoutId----: ', checkoutId);
            await createCheckout({ checkoutId });
          } catch (error) {
            console.error("Error creating checkout:", error);
            setProductAvailability("unavailable");
            setAddToCartClicked(false);
          }
           setCheckoutLoaded(true);
        
        }
    // }
        loadShopifyCheckout();
      }, [userInfo]);
      // Update product availability once checkout is loaded
      useEffect(() => {
        async function updateProductAvailability() {
          const shopifyProductId =
            "gid://shopify/Product/" + eachProduct?.shopifyId;
          try {
            const shopifyProd = await getClient().product.fetch(shopifyProductId);
            if (shopifyProd) {
              const variant = shopifyProd?.variants[0];
        

              if (variant) {
                setProductVariantId(variant?.id);
                setProductAvailability("available");
              }else{
            setProductAvailability("unavailable");

              }
            }else{
            setProductAvailability("unavailable");

            }
          } catch (error) {
            console.error(
              "Error fetching product availability from Shopify:",
              error
            );
            setProductAvailability("unavailable");
            setAddToCartClicked(false);
          }
          setAvailabilityUpdated(true);
        }
        if (checkoutLoaded) {
          updateProductAvailability();
        }
      }, [checkoutLoaded]);
      function handleMouseEnter(){
    
        setHover(true)
      }
      function handleMouseLeave(){
        setHover(false)
      }
// validations
const addToCartHandlerPresent:any= (!checkoutLoaded || !availabilityUpdated || addToCartClicked) ? undefined:handleAddToCart
const increaseQuantityHandlerPresent:any=(!checkoutLoaded || !availabilityUpdated || addToCartClicked)? undefined:increaseQuantity
const decreaseQuantityHandlerPresent:any=(!checkoutLoaded || !availabilityUpdated || addToCartClicked)? undefined:decreaseQuantity
const productAvailabilityFromDesignDetails:boolean=eachProduct?.availability.toLowerCase() ==="available"
const productAvailabilityFromShopify:boolean=productAvailability.toLowerCase()==='available'
const singleViewMode:boolean=(viewMode==='single')
const gridViewMode:boolean=(viewMode==='grid')
      return (
        <>
        <Grid item xs={gridViewMode ? 4 : 12} sm={6} md={4} mb={2} key={index} onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div style={{position:'relative'}} >
            <div  style={{position:'relative'}}>
            <a href={`/product/${eachProduct?.productSkuID}`}>
            <Box
            
              onClick={()=>{gtmClickTracking({
                event:'Product Tile Selected',
                pageType: "new design details page pop-up",
                product:eachProduct
              })}}
              component="img"
              src={eachProduct?.imageLink}
              alt={`Image ${index + 1}`}
              sx={{
                position:'relative',
                width: '100%',
                height:gridViewMode?70: 240,
                objectFit: 'contain', 
                border:"1px solid #e0e0e0",
                borderRadius:"8px",
                overFlow:'hidden',
              }}
              
            />
            </a>
            {( !productAvailabilityFromDesignDetails||
                     !productAvailabilityFromShopify)&& (
                    <Box component="div"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: '7px',
                        backgroundColor: "rgba(178, 178, 178, 0.4)",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "#FFFFFF", fontWeight: "bold" ,fontSize:viewMode==='grid'?'10px':null}}
                      >
                        Unavailable
                      </Typography>
                    </Box>
                  )}
            </div>
            {/* 3d link */}
               {(eachProduct?.model3dLink&&!(gridViewMode ))&& (
                  <Box
                    component="div"
                    sx={{
                      position: "absolute",
                      top: 12,
                      left: 8,
                      display: "flex",
                      alignSelf: "flex-start",
                      cursor: "pointer",
                     
                    }}
                  >
                    <ViewInArIcon sx={{fontSize:'24px'}}/>
                  </Box>
                )}
                {/* add to cart button */}
              {(productAvailabilityFromDesignDetails&&productAvailabilityFromShopify)&&
                  <Box component="div"
                    sx={{
                      display:isMobile||(quantity > 0) || hover ||expanded ? "flex" : "none",
                      alignItems: "center",
                      justifyContent:'center',
                      position: "absolute",
                      top:gridViewMode?-8:6,
                      right:gridViewMode?-6: 6,
                      left:(gridViewMode&&expanded)?-8:null,
                      backgroundColor: quantity >0||expanded ? "#0D908E" :"transparent",
                      borderRadius: expanded?'15px':'50%',
                      height:gridViewMode?'24px':null,
                      paddingLeft:gridViewMode?'1px':(quantity>0?'6px':''),
                      paddingRight:gridViewMode?'1px':(quantity>0?'6px':''),
                    }}
                    
                  >
                    {!expanded && (
                      (quantity > 0 )?(<IconButton
                        onClick={addToCartHandlerPresent
                        }
                        sx={{
                          cursor:"pointer",
                          fontSize: "0.875rem",
                          borderRadius: quantity >= 0 ? "" : "50%",
                          padding: quantity >= 0 ? "" : "6px",
                        
                        }}
                      >
                            <Typography color="white" variant="caption"  sx={{fontSize: "1.2rem"}}>
                             {' '} {quantity}
                            </Typography>
                      </IconButton>):(<img  onClick={addToCartHandlerPresent
                        } style={{width:viewMode==='grid'?'27px':'35px',cursor:'pointer'}} src={CartIcon.src} alt="cart-icon" />)
                    )}
                    {quantity >= 0 && expanded && (
                      <Box component="div"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent:'space-between !important'
                        }}
                      >
                        <IconButton onClick={decreaseQuantityHandlerPresent} sx={{paddingRight:gridViewMode?'25px':null}}>
                          <RemoveIcon sx={{ color: "white", fontSize: "1.3rem" }} />{" "}
                        </IconButton>
                        <Typography color="white" variant="caption" sx={{fontSize: "1.3rem"}}>
                          {quantity}
                        </Typography>{" "}
                        <IconButton onClick={increaseQuantityHandlerPresent} sx={{paddingLeft:gridViewMode?'25px':null}}>
                          <AddIcon sx={{ color: "white", fontSize: "1.3rem" }} />{" "}
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                }
             {/* text section */}
                    <Box component = "div"
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        textAlign:gridViewMode?"flex-start":'center'
                      }}
                    >
                <Typography variant="body1" fontWeight={gridViewMode?'':"bold"} sx={{fontSize:gridViewMode?'11px':'12.5px'}}>
                  {eachProduct?.title.slice(0,(gridViewMode)?28:eachProduct?.title.length)}
                  {(gridViewMode&&eachProduct?.title.length>28)&&'...'}
                </Typography>
                {singleViewMode&&<Typography sx={{fontSize:'12.5px'}}>
                  <span
                    style={{
                      color: "#0D908E",
                    }}
                  >
                    {(productAvailabilityFromDesignDetails&&productAvailabilityFromShopify)
                      ? "In Stock"
                      : "Not In Stock"}
                  </span>
                  {" • Free Shipping"}
                </Typography>}
  
                <Typography variant="body1" color="primary" sx={{fontSize:(gridViewMode)?'12px':'14px'}}>
                <span style={{ textDecoration: "line-through", color: "black" }}>
                    ${eachProduct?.comparePrice}
                  </span>{" "}
                  ${eachProduct?.finalPrice}
                  
                </Typography>
                    </Box>
            </div>
          
          </Grid>
        </>
      );
    }
  );
  EachProductCard.displayName = "EachProductCard";
  export default EachProductCard
//design overlay component
   const DesignOverlay: React.FC<DesignOverlayProps> = ({
    open,
    onClose,
    design={},
    onStartDesign=()=>{},
    recommendations=[],
    handleRecommendationChange=()=>{},
    showFavFromFavList,
    ontoggleFav=()=>{}
  }) => {
    // console.log(design,'design---------------------------------')
    const [loading,setLoading]=useState<boolean>(true)
    const[userInfo,setUserInfo]=useState<any>({})
    useEffect(()=>{
       async function fetchLatestUserInfo(){
       const res=await getUserInfo('',true)
       await getUserCartInfo(res)
       setUserInfo(res)
       setLoading(false)
       }
       fetchLatestUserInfo()
    },[])
    const [onClickAddAllToCart, setOnClickAddAllToCart] = useState(false);
    const [onClickSeeInYourSpace, setSeeInYourSpace] = useState(false);
    const router = useRouter();
    const isMobile = useMediaQuery("(max-width:600px)");
    type ChildState = {
      checkoutLoaded: boolean;
      availabilityUpdated: boolean;
    };
    
    const [childStates, setChildStates] = useState<ChildState[]>([]);
    const handleStateChange :any= useCallback((index:any, newState:any) => {
      setChildStates((prevStates) => {
        const updatedStates:any = [...prevStates];
        updatedStates[index] = newState;
        return updatedStates;
      });
    }, [open]);
    // Logic to check if all children have loaded
    const allCheckoutLoaded = childStates.every((state) => state?.checkoutLoaded);
    const allAvailabilityUpdated = childStates.every(
      (state) => state?.availabilityUpdated
    );
    useEffect(() => {
      if (open) {
        gtmPageOnloadTracking({
          event: "design_overlay_viewed",
          pageType: "Design overlay",
          designName: design.displayName,
        });
      } else {
        gtmPageOnloadTracking({
          event: "design_overlay_closed",
          pageType: "Room Designs page",
        });
      }
    }, [open, design?.displayName]);
    const componentRefs = useRef<any[]>([]);
  
    const handleAddAllToCart = async () => {
      gtmClickTracking({
        event:'Add All Products',
        pageType:'new design details page pop-up',
        addedProducts:design?.products
      })
      setOnClickAddAllToCart(true);
      try {
        for (let ref of componentRefs.current) {
          if (ref && ref?.handleAddAllToCartRef) {
            await ref.handleAddAllToCartRef();
          }
        }
    //   setOnClickAddAllToCart(false);
    //   navigateWithHistory(router, `/cart`);
    router.push('/cart')

      } catch (error) {
        console.error("Error adding all products to cart:", error);
      }
    };
   useEffect(() => {
    const handleRouteChangeComplete = () => {
    onClose()
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // Cleanup event listener on unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);
    // const showAddAllToCartButton = design?.products?.some(
    //   (eachProduct: any) =>
    //     eachProduct?.availability.toLowerCase() === "available"
    // );
   const showAddAllToCartButton = childStates?.some(
      (eachProduct: any) =>
        eachProduct?.productAvailability.toLowerCase() === "available"
    );
    const [viewMode, setViewMode] = useState("single");
    const [showMoreText, setShowMoreText] = useState(false);
    // const [favListItems, setFavListItems] = useState([]);

    // console.log(favListItems,userInfo,'favitem list-----------------------------')
    // const isfavExist = favListItems?.find(
    //   (eachDesign: any) => eachDesign?.itemId === design?.designId
    // );
    const textLength = showMoreText
      ? design?.description?.length
      : isMobile
      ? 150
      : design?.description?.length;
    const CUSTOMER_FAVORITES_URL =
      "https://eh94zzqmpb.execute-api.us-east-1.amazonaws.com/dev/nest/v1/api/customer-favorites";
    const postFavHandler = async (item_id: any, currFavState: any) => {
      const postData = {
        customerId: userInfo?.cId,
        itemId: item_id,
        type: "design",
      };
      try {
        let response;
        if (currFavState) {
          response = await axios.delete(CUSTOMER_FAVORITES_URL, {
            data: postData,
          });
          gtmClickTracking({
            event:'Design Unfavorited',
            pageType: "new design details page pop-up",
            designUnFavorited:design
          })
        } else {
          response = await axios.post(CUSTOMER_FAVORITES_URL, postData, {
            accept: "application/json",
          } as any);
          gtmClickTracking({
            event: "Design Favorited",
            pageType: "new design details page pop-up",
            designFavorited:design
          })
        }
        // const makeFetchCall = async () => {
        //   const response = await axios.get(
        //     `https://nestingale.com/v1/api/customer-favorites?customerId=${userInfo?.cId}`
        //   );
        //   setFavListItems(response?.data);
        
        // };
        // makeFetchCall();
      } catch (e) {
        console.log(e,'err at fav');
      }
    };
    const toggleViewMode = () => {
      if(viewMode==='single'){
        gtmClickTracking({
          event:'Grid View',
          pageType: "new design details page pop-up",
          design,
        })
      }else{
        gtmClickTracking({
          event:'Single View',
          pageType: "new design details page pop-up",
          design,
        })
      }
      setViewMode(viewMode === "grid" ? "single" : "grid");
    };
    // useEffect(() => {
    //   async function makeFetchCallToGetFav() {
    //     if (userInfo?.cId) {
    //       try {
    //         const response = await axios.get(
    //           `https://nestingale.com/v1/api/customer-favorites?customerId=${userInfo?.cId}`
    //         );
    //         setFavListItems(response?.data);
        
    //       } catch (error) {
    //         console.log(error,'err at get fav items');
    //       }
    //     }
    //   }
    //   makeFetchCallToGetFav();
    // }, [open, userInfo?.cId]);
    useEffect(() => {
      if (!isMobile) {
        setViewMode("single");
      }
    }, [isMobile]);
    const theme:any = useTheme();
    // const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isSmUp = useMediaQuery(theme.breakpoints.down('sm'));
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
        fullWidth
        
      >
        <DialogContent sx={{paddingBottom:0 ,paddingLeft:0,paddingRight:0,paddingTop:isSmUp?0:'20px'}} >
          {isSmUp && (
            <div
            id="dialogBoxMainContainer"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                paddingLeft:'24px',
                paddingRight:'24px',
                paddingTop:!isSmUp?0:'20px'
              }}
            >
              <div
                style={{
                  backgroundColor: "#FE6526",
                  height: "21px",
                  marginRight: "10px",
                }}
              >
                {/* <img
                  src="https://nestingale.com/_next/static/media/personalise_icon_1.50582332.png"
                  alt=""
                  width={20}
                /> */}
              </div>
              <Typography variant="h3" fontWeight="bold">
                {design?.displayName}
              </Typography>
            </div>
          )}
          <IconButton
            onClick={onClose}
            style={{
              position: "absolute",
              top: isMobile ? 5 : 10,
              right: isMobile ? 10 : 18,
              backgroundColor: "white",
              color: "black",
              borderRadius: "50%",
              padding: "6px",
              zIndex: 10,
            }}
          >
            <Close />
          </IconButton>
          <Grid container  spacing={isSmUp?1:4}  sx={{paddingLeft:isMobile?0:'24px',paddingRight:isMobile?0:'24px'}} id="dialogBoxMainContainer">
            {/* Left Side: Main Image with Wishlist Icon */}
            <Grid item xs={12} sm={12} md={8} position="relative">
              {loading?<Skeleton
                         width='100%'
                         height={isMobile?250:500} 
                         style={{borderRadius:isMobile?0: "8px"}}/>:<div style={{ position: "relative" }}>
                <Image
                  src={design?.designImages}
                  alt={design?.displayName}
                  height={isMobile?250:500}
                  width={800}
                  style={{ borderRadius:isMobile?0: "8px", width: "100%",objectFit:'cover' }}
                />
                {/* Wishlist Icon */}
                <IconButton
                  onClick={() => {
                    // console.log(showFavFromFavList,'true--------------')
                    // console.log(userInfo?.cId,'cid from design details page')
                    ontoggleFav(design?.designId,showFavFromFavList?(showFavFromFavList?.isFav?false:true):(design?.isFavorite ?false:true),userInfo?.cId)
                    // ontoggleFav(design?.designId,isfavExist ?false:true,userInfo?.cId)
                    // postFavHandler(design?.designId, isfavExist ? true : false);
                  }}
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 7,
                    backgroundColor: "transparent",
                    color:showFavFromFavList?(showFavFromFavList?.isFav? "#FE6526" : "rgb(37 40 43)"):(design?.isFavorite ? "#FE6526" : "rgb(37 40 43)"),
                    
                    // color: isfavExist ? "#FE6526" : "rgb(37 40 43)",
                    // color:showFavFromFavList?(showFavFromFavList?.isFav? "#FE6526" : "rgb(37 40 43)"):(isfavExist ? "#FE6526" : "rgb(37 40 43)"),
                    borderRadius: "50%",
                    padding: "6px",
                  }}
                >
                  {showFavFromFavList?(showFavFromFavList?.isFav?<Favorite sx={{fontSize:'35px'}}/> : <FavoriteBorder sx={{fontSize:'35px'}}/>):(design?.isFavorite ? <Favorite sx={{fontSize:'35px'}}/> : <FavoriteBorder sx={{fontSize:'35px'}}/>)}
                    
                  {/* {isfavExist ? <Favorite sx={{fontSize:'35px'}}/> : <FavoriteBorder sx={{fontSize:'35px'}}/>} */}
                  {/* {showFavFromFavList?(showFavFromFavList?.isFav?<Favorite sx={{fontSize:'35px'}}/> : <FavoriteBorder sx={{fontSize:'35px'}}/>):(isfavExist ? <Favorite sx={{fontSize:'35px'}}/> : <FavoriteBorder sx={{fontSize:'35px'}}/>)} */}
                </IconButton>
              </div>}
            </Grid>
  
            {/* Right Side: Title, Description, and "See in your space" Button */}
            <Grid item xs={12} sm={12} md={4} sx={{paddingLeft:!isMobile?0:'24px !important',paddingRight:!isMobile?0:'24px !important'}} >
              <Box component="div">
                {!isSmUp && (
                  
                    <Typography variant="h2" fontWeight="bold">
                      {design?.displayName}
                    </Typography>
              
                )}
                {design?.description?.length>0&&<Typography variant="body1" mt={isMobile?0:2} sx={{fontSize:isMobile?'14px':'15px'}}>
                  {design?.description?.slice(0, textLength)}
                  <span
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowMoreText((prev) => !prev)}
                  >
                    {design?.description?.length > 0
                      ? isMobile
                        ? showMoreText
                          ? "Less"
                          : "...More+"
                        : ""
                      : ""}
                  </span>
                </Typography>}
                {design?.isPersonalizable&&<div style={{textAlign:isSmUp?'center':'start'}}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    gtmClickTracking({
                      event:'View in my space',
                      pageType: "new design details page pop-up",
                      design,
                    })
                    // setSeeInYourSpace(true);
                    // onClickSeeInYourSpace ? undefined : onStartDesign();
                    onStartDesign();
                  }}
                  sx={{
                    mt:2,
                    borderColor: "#0D908E",
                    color: "#0D908E",
                    backgroundColor: "white",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#0D908E",
                    },
                    
                  }}
                  size="large"
                >
                  {onClickSeeInYourSpace ? "Loading.." : "See in your space"}
                </Button>
                </div>}
               
              </Box>
            </Grid>
          </Grid>
  
          {/* Featured Products in a Column with "Add to Cart" Button */}
          <Box component="div" mt={isMobile?2:4}  sx={{paddingLeft:'24px',paddingRight:'24px'}}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold" mb={2} sx={{fontSize:'20px'}}>
                Featured in this design:
              </Typography>
              {/* Toggle Button for Grid/Single View (Mobile Only) */}
              {isMobile && (
                <Box component="div" display="flex" justifyContent="flex-end" mb={2}>
                  <IconButton onClick={toggleViewMode}>
                    {viewMode === "grid" ? <img src={viewModeSingleIcon.src} alt="single-view-icon" style={{width:'25px'}}/> : <img style={{width:'25px'}} src={viewModeGridIcon.src} alt="grid-view-icon" />}
                  </IconButton>
                </Box>
              )}
            </div>
            {/* new layout */}
            <Box component="div" sx={{ flexGrow: 1, padding: 1}}>
        <Grid container spacing={2} justifyContent="flex-start">
          {design?.products?.map((eachProduct:any, index:number) => (
            <EachProductCard  key={eachProduct?._id}
            eachProduct={eachProduct}
            ref={(el: any) => (componentRefs.current[index] = el)}
            viewMode={viewMode}
            userInfo={userInfo}
            onStateChange={handleStateChange}
            index={index}/>
          ))}
        </Grid>
      </Box>
          </Box>
          {/* recommendation section */}
          {recommendations?.length > 0 && (
            <div style={{marginTop:'30px',paddingLeft:'24px',paddingRight:'24px'}}>
              <div>
                
              <Typography variant="subtitle1" fontWeight="bold" mb={1} sx={{fontSize:'20px'}}>
                  More Designs For You:
                </Typography>
              </div>
              <div>
              <Box component="div" sx={{ flexGrow: 1, padding: 1 }}>
        <Grid container spacing={2} justifyContent="flex-start">
          {recommendations?.map((eachRecommendation:any,index:number) => (
            <Grid item xs={viewMode === 'grid' ? 4 : 12} sm={6} md={4} mb={viewMode==='grid'? undefined:2}  key={eachRecommendation?.designId}>
              <Box
                onClick={() => {
                                    handleRecommendationChange(eachRecommendation);
                                    const element :any= document.getElementById(
                                      "dialogBoxMainContainer"
                                    );
                                    element?.scrollIntoView({
                                      behavior: "smooth",
                                      block: "start",
                                    });
                                  }}
                component="img"
                src={eachRecommendation?.designImages}
                alt={`Image ${index + 1}`}
                sx={{
                  width: '100%',
                  height:viewMode === 'grid'?80: 240,
                  objectFit: 'cover', 
                  borderRadius:'10px',
                  cursor:'pointer'
                }}
              />
              
              <Typography
                        variant={viewMode==='grid'?'h6':"h5"}
                        fontWeight="bold"
                        textAlign="center"
                        mt={viewMode==='grid'?0:1}
                      >
                        {eachRecommendation?.displayName?.slice(
                          0,
                          viewMode === "grid"
                            ? 7
                            : eachRecommendation?.displayName?.length
                        )}
                        {viewMode === "grid" && "..."}
                      </Typography>
                      
            </Grid>
          ))}
        </Grid>
              </Box>
              </div>
            </div>
      
          )}
  
          <Box component="div"
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
  
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderTop: "1px solid #e0e0e0",
              p: 2,
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
              display: "flex",
              justifyContent: "center",
              zIndex: 10,
            }}
          >
             <Button
              variant="contained"
              color="primary"
              sx={{ width: "300px" }}
              // startIcon={<AddShoppingCart />}
              onClick={
                onClickAddAllToCart
                  ? undefined
                  : !allCheckoutLoaded || !allAvailabilityUpdated
                  ? undefined
                  : showAddAllToCartButton
                  ? handleAddAllToCart
                  : undefined
              }
            >
              {(!allCheckoutLoaded || !allAvailabilityUpdated)
                ? "loading.."
                :( showAddAllToCartButton
                ? onClickAddAllToCart
                  ? "Adding All To Cart..."
                  : "Add All to Cart"
                : "All Products Unavailable")}
            </Button>
            {/* <Button component="div"
              variant="contained"
              color="primary"
              sx={{ width: "300px" }}
              startIcon={<AddShoppingCart />}
              onClick={
                onClickAddAllToCart
                  ? () => {}
                  : showAddAllToCartButton
                  ? handleAddAllToCart
                  : () => {}
              }
            >
              {showAddAllToCartButton
                ? onClickAddAllToCart
                  ? "Adding All To Cart..."
                  : "Add All to Cart"
                : "All Products Unavailable"}
            </Button> */}
          </Box>
        </DialogContent>
      </Dialog>
    );
  };
  DesignOverlay.displayName='DesignOverlay'
  export {DesignOverlay}