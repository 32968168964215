import React, { useEffect, useState } from "react";
import RoomPlanSelector from "./RoomPlanSelector";
import CreateRoomPlan from "./CreateRoomPlan";
import ScanRoomPlan from "./ScanRoomPlan";
import { useRouter } from "next/router";
import LoginComponent from "@/components/login";
import ProjectCreationSuccessModal from "./ProjectCreationSuccessModal";

interface RoomPlanOnboardingProps {
  designImage?: any;
  onCloseOverlay?: Function;
  handleOverlayBack?: Function;
  currentRoomType?: string;

}


const RoomPlanOnboarding: React.FC<RoomPlanOnboardingProps> = ({designImage, onCloseOverlay, handleOverlayBack, currentRoomType}) => {
    const router = useRouter();
    const [activeComponent, setActiveComponent] = useState('');
    const [isCreateRoomPage, setCreateRoomPage] = useState(true);

    const renderComponent = () => {
      switch (activeComponent) {
        case 'scanRoomPlan':
          return <ScanRoomPlan setActiveComponent={setActiveComponent} designImage={designImage} onCloseOverlay={onCloseOverlay}/>;
        case 'createRoomPlan':
          return <CreateRoomPlan setActiveComponent={setActiveComponent} designImage={designImage} onCloseOverlay={onCloseOverlay} currentRoomType = {currentRoomType} isModelRequired={isCreateRoomPage} />; 
        default:  
          return <RoomPlanSelector setActiveComponent={setActiveComponent} designImage={designImage} onCloseOverlay={onCloseOverlay} handleOverlayBack={handleOverlayBack}/>;
      }
    };

    useEffect( ()=> {
      if(router.query && router.query.createRoomPlan) {
        setActiveComponent('createRoomPlan');
        setCreateRoomPage(false);
      }
    }, [router.query.createRoomPlan]);
    
    return (
        <>
            {renderComponent()}
        </>
    );
};

export default RoomPlanOnboarding;
