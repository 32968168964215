import { Box, Typography } from "@mui/material";
import Link from "next/link";
import { CarouselCardData as CardData } from "../carousal/carousal_card";
import cssStyles from "./category_cards.module.scss";
import React from "react";
import Image from 'next/image';

export default function GenerateCategoryCard(cards: CardData[], actionFn?: Function, 
height?: string, width?: string, pageName?: string): any[] {
    const categoryCardsEls: any[] = [];

    cards?.forEach((card: CardData, index: number) => {
        const prodImage = card.imageUrl;

        categoryCardsEls.push(
            <Box component='div' className={cssStyles["card"]} key={index} onClick={(e) => actionFn && actionFn(e, index, card, pageName)}>

                <div>
                    <a href={card.link ?? "#"}>
                        <Image
                            src={prodImage || ""}
                            alt={card.name || ""}
                            width={parseInt(width as string ?? "200")}
                            height={parseInt(height as string ?? "200")}
                            objectFit="contain"
                            className={cssStyles["image-wrapper"]}
                        />
                    </a>
                </div>

                <Typography className={cssStyles["text"]} component="div">
                    {card.name}
                </Typography>
            </Box>
        );
    });

    return categoryCardsEls;
}