import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Box, List, ListItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import BuilderSelct from '@/store/builderStoreSelector';
import { ActiveSelection, Texture } from '@/store/builderSlice';
import { actions as builderActions } from "@/store/builderSlice";
import { useDispatch } from 'react-redux';
import { ProductPlacementType, SurfaceType } from '@/utils/builder/builderConstants';
import WallColorPicker from './WallColorPicker';
import WallColorPicker2 from './WallColorPicker2';

interface WallTextureTrayProps {
  isTextureNotRequired?: boolean;
  onColorUpdate?: Function;
}

export default function WallTextureTray({ isTextureNotRequired = false, onColorUpdate }: WallTextureTrayProps) {
  const dispatch = useDispatch();
  const [optionType, setOptionType] = useState('Textures');

  const textures: Texture[] = BuilderSelct.designContent().textures;
  const activeSelection: ActiveSelection = BuilderSelct.activeSelection();
  const wallTextures: Texture[] = textures.filter(texture => texture.surfaceType === 'Wall');

  const dispatchUpdateWallTextureAction = (productId: string, type: string) => {
    if (!productId || !type) return;
    if (activeSelection.wallId && activeSelection.wallId !== '')
      dispatch(builderActions.addProductToProject({
        productId: productId,
        surfaceType: SurfaceType.WALL,
        surfaceId: activeSelection.wallId,
        type: type === 'image' ? ProductPlacementType.Texture : ProductPlacementType.Color,
        undoable: true,
      }));
    else
      dispatch(builderActions.updateTextureForAllWalls({
        productId: productId,
        surfaceType: SurfaceType.WALL,
        type: type === 'image' ? ProductPlacementType.Texture : ProductPlacementType.Color,
        undoable: true,
      }));
  }

  const updateWallTexture = (newValue: Texture | null) => {
    newValue && dispatchUpdateWallTextureAction(newValue.id, newValue.type);
  }

  const addAndApplyWallColor = useCallback((color: string) => {
    if(onColorUpdate) {
      let colorObj = {
        id: color,
        name: color,
        hexCode: color,
        type: 'color',
        surfaceType: 'Wall'
      };
      onColorUpdate(colorObj);
    }
    dispatch(builderActions.addWallTextureColorContent({
      texture: {
        id: color,
        name: color,
        hexCode: color,
        type: 'color',
        surfaceType: 'Wall'
      }
    }));
    dispatchUpdateWallTextureAction(color, ProductPlacementType.Color);
  }, [dispatchUpdateWallTextureAction]);

  const toggleOptionType = (
    event: React.MouseEvent<HTMLElement>,
    newOptionType: string | null,
  ) => {
    newOptionType && (setOptionType(newOptionType));
  };

  const showTextures = () => {
    return <List sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto', width: '100%', maxWidth: 120, bgcolor: 'background.paper' }}>
      {wallTextures.map((texture: Texture) => (
        <ListItem alignItems="flex-start" key={texture.id}>
          <img
            src={texture.textureUrl}
            style={{ maxWidth: '100%' }}
            draggable={false}
            onClick={() => updateWallTexture(texture)}
          />
        </ListItem>
      ))}
    </List>
  }

  return (
    <>
     { !isTextureNotRequired && <Box component={'div'} sx={{ width: 200 }}>
        <ToggleButtonGroup
          value={optionType}
          exclusive
          sx={{ width: 200 }}
          onChange={toggleOptionType}
        >
          <ToggleButton value="Textures" aria-label="left aligned" sx={{ width: 100 }}>
            Textures
          </ToggleButton>
          <ToggleButton value="Colors" aria-label="centered" sx={{ width: 100 }}>
            Colors
          </ToggleButton>
        </ToggleButtonGroup>
      </Box> }
      {
        !isTextureNotRequired &&
        optionType === 'Textures' ? showTextures() : <WallColorPicker2 updateColor={addAndApplyWallColor} applyColor={addAndApplyWallColor} />
      }

    </>

  );
}