import Head from 'next/head';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderComponent from './header';
import FooterComponent from './footer';
import CmsRenderer from "@/components/cms_renderer";
import SearchBar from './product_search_bar';
import { useEffect, useState } from 'react';
export default function Layout(props) {
  const[searchBarOpen,setSearchBarOpen]=useState(false)
  const [query, setQuery] = useState(()=>{
    if (typeof window !== 'undefined') {
      return sessionStorage.getItem('userSearchQuery') || '';
    }
    return ''; 
  })
  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('userSearchQuery', query);
    }
  }, [query]);
  const { children, brand, popupView, headerType, globalData,  loginEl, setLoginEl, headerMenuJson, pageInserts, env } = props;
  let path = props.path.split("?")[0];
  const isPopupView = popupView || false;
  const noHeaderFooterList = [
    "login",
    "successconfirmation",
    'product3d'
  ];
  const shortHeaderList = [
    "stylequiz",
    "styleprofile",
    "roomquiz",
    "recommendations",
    "builder",
    "cart",
    "uploadInstructions"
  ]
  return (
    <>
      {!isPopupView &&
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>}
      {/* <div className="promo_banner">
        <span>Memorial Day 2024 Sale - Save Up to 30% Off Home Decor <a href="/deals">See All Deals</a></span>
      </div> */}
      <div className={`main-wrapper ${path === '/' ? 'full_width' : ''}`}>
        <div id="signup-a">
          {!isPopupView && path && !path.includes('threed') && !noHeaderFooterList.includes(path.split("/")[1])
            && (
            <>
            <div className='header_container'>
              <HeaderComponent  
                loginEl={loginEl} 
                setLoginEl={setLoginEl} 
                loginCheckLoading={props.loaded} 
                brand={brand} 
                globalData={globalData} 
                headerType={shortHeaderList.includes(path.split("/")[1]) ? 'short' : 'full'}
                headerMenuJson={headerMenuJson}
                headerTopCmsData={pageInserts.headerTop}
                headerBottomCmsData={pageInserts.headerBottom}
                searchBarOpen={searchBarOpen}
                setSearchBarOpen={setSearchBarOpen}
              />
            </div>
            {searchBarOpen&&<div style={{height:'80px'}}>
                <SearchBar setSearchBarOpen={setSearchBarOpen} query={query} setQuery={setQuery}/>
             </div>}
             </>
          )}
          <div className='container_wrapper'>
            <CssBaseline />
            <main>{children}</main>
          </div>

        </div>
      </div>
      {pageInserts.footerContent && <CmsRenderer sectionCmsData={pageInserts.footerContent}  sectionCmsKey={'footerContent'} vendorName={brand} env={env} />}
      {!isPopupView && path && !path.includes('threed') && !noHeaderFooterList.includes(path.split("/")[1]) && (<div className='footersection'>
        <div className='footer_container'><FooterComponent brand={brand} /></div>
      </div>)}
    </>
  )
}