import { Project, RoomModel, TwoDRoomLayout, actions as builderActions } from "@/store/builderSlice";
import { gtmPageOnloadTracking } from "@/utils";
import { BUILDER_FAILED, BUILDER_IN_PROGRESS, BUILDER_SUCCESS } from "@/utils/builder/builderConstants";
import { getUserId } from "@/utils/builder/userUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const axiosObj = axios.create({
    baseURL: "https://d221a61rb87vel.cloudfront.net/v1"
});

const getValidRoomModels = (roomModels: RoomModel[], isCreateRoomFlow: boolean) => {
    return roomModels.filter(roomModel =>
        (isCreateRoomFlow || roomModel.roomLayoutType === '2D' || (roomModel.usdzUrl != null && roomModel.usdzUrl.indexOf('https://') !== -1 &&
            roomModel.glbUrl != null && roomModel.glbUrl.indexOf('https://') !== -1)) &&
        roomModel.roomType != null && roomModel.roomType !== '' &&
        roomModel.roomLabel != null && roomModel.roomLabel !== ''
    );
}
export const fetchRoomModels = createAsyncThunk(
    "builder/roomModels",
    async (config: {
        customerId: string,
        isCreateRoomFlow: boolean,
        showcase: boolean,
        roomType?: string,
        updateCurrentRoomId?: boolean,
        setDefaultRoom?: boolean,
        roomId?: string,
    }, { rejectWithValue }) => {
        try {
            let url = `api/rooms3Ddata?customerId=${config.customerId}&showcase=${config.showcase}`;
            if (config.roomId != null)
                url += `&roomId=${config.roomId}`;
            if (config.roomType != null)
                url += `&roomType=${config.roomType}`;
            const roomModelsData = await axiosObj.get(url)
                .then((response) => {
                    return response.data
                }, (err) => {
                    const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchRoomModels:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
                    console.log(errorObj);
                    throw errorObj;
                });

            //console.log("roomModelsData=", roomModelsData)
            return {
                roomModels: getValidRoomModels(roomModelsData.rooms, config.isCreateRoomFlow),
                updateCurrentRoomId: config.updateCurrentRoomId,
                setDefaultRoom: config.setDefaultRoom,
                roomId: config.roomId
            };
        } catch (err) {
            console.log({ description: "NEST_SPA:Slices:fetchRoomModels:Exception", errorCode: "NEST_SPA:BUILDER_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
            return rejectWithValue(err);
        }
    }
);

export const fetchRoomLabels = createAsyncThunk(
    "builder/roomLabels",
    async (config: { roomType: string }, { rejectWithValue }) => {
        try {
            let url = `api/customeronboarding/ReturnCustomerRoomLabel`;
            const postData = {
                customerId: getUserId(),
                roomType: config.roomType,
            }

            const roomLabelsData = await axiosObj.post(url, postData)
                .then((response) => {
                    return response.data
                }, (err) => {
                    const errorObj = Object.assign({ description: "NEST_SPA:Slices:fetchRoomLabels:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
                    console.log(errorObj);
                    throw errorObj;
                });

            return roomLabelsData;
        } catch (err) {
            console.log({ description: "NEST_SPA:Slices:fetchRoomLabels:Exception", errorCode: "NEST_SPA:BUILDER_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
            return rejectWithValue(err);
        }
    }
);

export const saveRoomLayout = createAsyncThunk(
    "builder/saveRoomLayout",
    async (config: {}, { rejectWithValue, getState, dispatch }) => {
        dispatch(builderActions.updateRoomLayoutSaveStatus({ saveStatus: BUILDER_IN_PROGRESS }));
        try {
            let url = `api/rooms3Ddata`;
            const twoDRoomLayout: TwoDRoomLayout = (getState() as any).builder.twoDRoomLayout;
            const projectJson: Project = Object.assign({}, (getState() as any).builder.project);

            let reqMethod = 'post';
            const postData = {
                customerId: getUserId(),
                roomId: '',
                roomType: twoDRoomLayout.roomType,
                roomLabel: decodeURIComponent(twoDRoomLayout.roomLabel),
                origin: ((window as any)?.webkit ? 'app' : 'web'),
                roomLayoutType: '2D',
                isInternalUser: window.localStorage.getItem('trafficType') === 'internal',
                data: {
                    vertices: twoDRoomLayout.vertices,
                    walls: twoDRoomLayout.walls,
                    floorTextureId: twoDRoomLayout.floorTextureId
                }
            }

            if (projectJson.roomModel.roomId) {
                postData.roomId = projectJson.roomModel.roomId;
                //reqMethod = 'put';
                (window as any)?.webkit?.messageHandlers.callbackHandler.postMessage(projectJson.roomModel.roomId);
            }
            else {
                (window as any)?.webkit?.messageHandlers.callbackHandler.postMessage("");
            }

            //@ts-ignore
            const roomModel: RoomModel = await axiosObj[reqMethod](url, postData)
                .then((response: any) => {
                    return response.data
                }, (err: any) => {
                    const errorObj = Object.assign({ description: "NEST_SPA:Slices:saveRoomLayout:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
                    throw errorObj;
                });

            console.log("roomLayout=", roomModel);

            gtmPageOnloadTracking({
                event: "room_saved_page_viewed",
                pageName: "Room Saved Screen",
            });
              
            return roomModel;
        } catch (err) {
            dispatch(builderActions.updateRoomLayoutSaveStatus({ saveStatus: BUILDER_FAILED }));
            console.log({ description: "NEST_SPA:Slices:saveRoomLayout:Exception", errorCode: "NEST_SPA:BUILDER_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
            return rejectWithValue(err);
        }
    }
);

export const saveRoomLayoutThumbnail = createAsyncThunk(
    "builder/saveRoomLayoutThumbnail",
    async (config: { image: string }, { rejectWithValue, getState, dispatch }) => {
        try {
            const roomModels: RoomModel[] = (getState() as any).builder.roomModels.models;
            const currentRoomModel = roomModels[roomModels.length - 1];
            const urls = [currentRoomModel.room2dFloorplanDimensionsImageSignedUrl];
            urls.push(currentRoomModel.room2dFloorplanImageSignedUrl);
            urls.push(currentRoomModel.thumbnailSignedUrl);

            const binary = atob(config.image.split(',')[1]);
            const array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            const image = new Blob([new Uint8Array(array)], { type: 'image/png' });
            let promises: any[] = [];
            urls.forEach(url => {
                promises.push(
                    axiosObj.put(url, image,
                        {
                            baseURL: '',
                            headers: {
                                'Content-Type': 'image/png'
                            }
                        }
                    )
                        .then((response: any) => {
                        }, (err: any) => {
                            const errorObj = Object.assign({ description: "NEST_SPA:Slices:saveRoomLayoutThumbnail:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
                            console.log(errorObj);
                            throw errorObj;
                        })
                )
            });

            Promise.all(promises)
                .then(() => {
                    dispatch(builderActions.updateRoomLayoutSaveStatus({ saveStatus: BUILDER_SUCCESS }));
                })
                .catch(function (err) {
                    const errorObj = Object.assign({ description: "NEST_SPA:Slices:saveRoomLayoutThumbnail:Exception", errorCode: "NEST_SPA:BUILDER_PAGE" }, err)
                    throw errorObj;
                });
        } catch (err) {
            dispatch(builderActions.updateRoomLayoutSaveStatus({ saveStatus: BUILDER_FAILED }));
            console.log({ description: "NEST_SPA:Slices:saveRoomLayoutThumbnail:Exception", errorCode: "NEST_SPA:BUILDER_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack });
            return rejectWithValue(err);
        }
    }
);


export const getRoomAssets = async () => {
    const baseURL = 'https://j2gn1sm2rl.execute-api.us-east-1.amazonaws.com/dev/nest/v1';
    const url = `/api/room-assets`;
    return await axiosObj({ url, baseURL })
        .then((response) => {
            console.log("---- fetch room assets : URL : " + url + " responnse : " + JSON.stringify(response.data));
            return response.data;
        }, (err) => {
            const errorObj = Object.assign({ description: "NEST_SPA::getRoomAssets:Exception", errorCode: "NEST_SPA:BUILDER_PAGE_ROOM_ASSETS" }, err)
            throw errorObj;
        });
}