import React from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

interface NotificationPopupProps {
  open: boolean;
  onClose: () => void;
  message: string;
  button1: string;
  button2: string;
  onKeepEditing: () => void;
  onStartDesigning: () => void;
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({
  open,
  onClose,
  message,
  button1,
  button2,
  onKeepEditing,
  onStartDesigning,
}) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="notification-heading">
      {/* <Box>
      <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
          </Box> */}
      <Box
      component='div'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '441px',
          height: '165px',
          bgcolor: 'background.paper',
          borderRadius: '20px',
          padding: '5px',
          textAlign: 'center',
          opacity: '0.9'
        }}
      >
        <IconButton onClick={onClose} size="small" sx={{
          display: 'flex',
          justifyContent: 'end',
          width: '100%',
        }}>
            <CloseIcon />
          </IconButton>

        <Box sx={{ width: '100%', justifyContent: 'center' }} component='div'>
          <Typography id="notification-heading" variant="h6" sx={{ fontWeight: 'medium', fontSize: '16px' }}>
            {message}
          </Typography>
        </Box>
        <Box component='div' sx={{ display: 'flex', justifyContent: 'center', width: '100% !important',gap: '10% !important', marginTop:'20px'}}>
          <Button
            variant="outlined"
            color="primary"
            className="mobile-save-button"
            sx={{
              width: '50% !important', 
            }}
            onClick={onClose}
          >
            {button1}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onStartDesigning}
            className="mobile-preview-button"
            sx={{
              width: '50% !important', 
            }}
          >
            {button2}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotificationPopup;
